import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import { Container } from './Shared'

import logo from '../assets/images/logo.svg'

const Header = () => {
  const history = useHistory()

  const navigateToHomepage = () => {
    history.replace('/')
  }

  return (
    <HeaderContainer>
      <FullPageBackgroud />
      <Container>
        <LogoContainer onClick={navigateToHomepage}>
          <Logo />
        </LogoContainer>
      </Container>
    </HeaderContainer>
  )
}

export default Header

const HeaderContainer = styled.div`
  position: relative;
  color: #fff;
  height: 200px;
`

const FullPageBackgroud = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 400px;
  background: #583091;

  &::after {
    content: '';
    position: absolute;
    top: -1000px;
    right: 0;
    bottom: 0;
    left: 0;
    background: #583091;
    transform-origin: top right;
    transform: skewY(-5deg);
  }
`

const LogoContainer = styled.div`
  cursor: pointer;
  height: 30px;
  width: 100%;
`

const Logo = styled.div`
  width: 220px;
  height: 100px;
  background: url(${logo}) no-repeat center;
`
