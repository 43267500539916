import styled from 'styled-components'

import { Button } from '../Shared'

const ContestButton = ({ children, onClick }) => {
  return <ContestBtn onClick={onClick}>{children}</ContestBtn>
}

export default ContestButton

const ContestBtn = styled(Button)`
  background: transparent;
  padding: 10px 0;
  border: 1px solid #d70040;
  color: #d70040;
  opacity: 0.2;
  transition: all 0.2s ease;
  text-align: center;
  width: 80px;
`
