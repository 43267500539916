import 'normalize.css'
import { createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'

import '../fonts.css'

const GlobalStyle = createGlobalStyle`

  body {
    font-family: 'Roboto', sans-serif;
    color: #222;
    background: ${rgba('#E2EDEE', 0.1)};
  }
`

export default GlobalStyle
