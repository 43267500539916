import { useEffect, useContext, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { format, addSeconds } from 'date-fns'
import { sv } from 'date-fns/locale'
import { IoMdRefresh } from 'react-icons/io'

import {
  Button,
  CalendarIcon,
  ClockIcon,
  Container,
  DataContainer,
  DataHead,
  DataTable,
  DataTitle,
  Input,
  InputContainer,
  InputLabel,
  OuterLeftTableHeader,
  OuterRightTableHeader,
  PageContainer,
  Title,
  Tooltip,
  TreatmentType,
} from '../components/Shared'
import Header from '../components/Header'
import { ContestModal, ConfirmModal, ExpirationModal } from '../components/modals'
import { ContestButton, UncontestButton } from '../components/buttons'
import EmptyTable from '../components/EmptyTable'

import globalContext from '../global-context'

const InvoicePage = () => {
  const { id } = useParams()
  const {
    invoice,
    fetchInvoice,
    contestBooking,
    contestCall,
    approveInvoice,
    authErrorMessage,
    isAuthError,
  } = useContext(globalContext)
  const [contest, setContest] = useState(null)
  const [bookingSearch, setBookingSearch] = useState('')
  const [callSearch, setCallSearch] = useState('')
  const [bookings, setBookings] = useState([])
  const [calls, setCalls] = useState([])
  const [duplicateCalls, setDuplicateCalls] = useState([])
  const [openedRow, setOpenedRow] = useState(null)
  const [period, setPeriod] = useState(null)
  const [ascending, setAscending] = useState(true)

  const history = useHistory()

  const formattedTime = seconds => {
    let time = addSeconds(new Date(0), seconds)
    return format(time, 'mm:ss')
  }

  const onContest = async ({ reason, description, type = null, id: entityId = null }) => {
    const contestType = type || contest.type
    const contestId = entityId || contest.id

    switch (contestType) {
      case 'booking':
        await contestBooking({
          id: contestId,
          reason,
          description,
          contest: true,
        })
        break

      case 'call':
        let currentCall = calls.find(call => call.id === contestId)
        let duplicates = matchDuplicates(currentCall, duplicateCalls)

        if (duplicates.length > 0) {
          duplicates.map(async call => {
            await contestCall({
              id: call.id,
              reason,
              description,
              contest: true,
            })
          })
        }

        await contestCall({
          id: contestId,
          reason,
          description,
          contest: true,
        })
        break

      default:
        break
    }

    setContest(null)
    fetchInvoice(id)
  }

  const unContest = async ({ id: entityId, type }) => {
    switch (type) {
      case 'booking':
        await contestBooking({
          id: entityId,
          contest: false,
        })
        break

      case 'call':
        await contestCall({
          id: entityId,
          contest: false,
        })
        break

      default:
        break
    }

    fetchInvoice(id)
  }

  const onApprove = async () => {
    await approveInvoice(id)
    history.push('/approved')
  }

  const onClearInput = e => {
    let type = e.target.dataset.type
    type === 'booking'
      ? bookingSearch.length !== 0 && setBookingSearch('')
      : callSearch.length !== 0 && setCallSearch('')
  }

  const filterBookings = useCallback(
    bookings => {
      const searchBookings = bookings
        .filter(
          booking =>
            booking.patient.name.toLowerCase().includes(bookingSearch) ||
            booking.patient.ssn.toLowerCase().includes(bookingSearch) ||
            booking.patient.phone.toLowerCase().includes(bookingSearch) ||
            booking.patient.email.toLowerCase().includes(bookingSearch)
        )
        .map(booking => ({ ...booking, fromSearch: true }))

      const normalBookings = bookings
        .filter(
          booking =>
            !booking.patient.name.toLowerCase().includes(bookingSearch) &&
            !booking.patient.ssn.toLowerCase().includes(bookingSearch) &&
            !booking.patient.phone.toLowerCase().includes(bookingSearch) &&
            !booking.patient.email.toLowerCase().includes(bookingSearch)
        )
        .map(booking => ({ ...booking, fromSearch: false }))

      let allBookings = [...searchBookings, ...normalBookings]
      setBookings(allBookings)

      return allBookings
    },
    [bookingSearch]
  )

  const filterCalls = useCallback(
    calls => {
      const searchCalls = calls
        .filter(call => {
          const listPhone = call.phone.replace(/[+*]*/g, '')

          const searchPhone =
            callSearch.replace('+', '').slice(0, 4) + callSearch.replace('+', '').slice(8, 11)

          return listPhone.includes(searchPhone)
        })
        .map(call => ({ ...call, fromSearch: true }))

      const normalCalls = calls
        .filter(call => {
          const listPhone = call.phone.replace(/[+*]*/g, '')

          const searchPhone =
            callSearch.replace('+', '').slice(0, 4) + callSearch.replace('+', '').slice(8, 11)

          return !listPhone.includes(searchPhone)
        })
        .map(call => ({ ...call, fromSearch: false }))

      let allCalls = [...searchCalls, ...normalCalls]
      setCalls(allCalls)

      return allCalls
    },
    [callSearch]
  )

  const openModal = e => setOpenedRow(parseInt(e.currentTarget.id))

  const sortBookingColumns = e => {
    let item = e.target.id

    switch (item) {
      case 'date':
        sortBookingItems('date')
        break
      case 'name':
        sortBookingItems('name')
        break
      case 'ssn':
        sortBookingItems('ssn')
        break
      case 'phone':
        sortBookingItems('phone')
        break
      case 'email':
        sortBookingItems('email')
        break
      case 'type':
        sortBookingItems('type')
        break
      default:
        break
    }

    function sortBookingItems(item) {
      let keyItem
      bookings.map(booking =>
        Object.keys(booking).find(key => (key === item ? (keyItem = key) : null))
      )
      if (!ascending) {
        setBookings([
          ...bookings.sort((a, b) => {
            let itemA, itemB
            // return key that is found inside a booking object (not nested)
            if (keyItem) {
              itemA = Object.entries(a).find(key => key[0] === keyItem)
              itemB = Object.entries(b).find(key => key[0] === keyItem)
              return itemA[1] > itemB[1] ? 1 : -1
            } else {
              // return key inside patient object
              itemA = Object.entries(a.patient).find(key => key[0] === item)
              itemB = Object.entries(b.patient).find(key => key[0] === item)
              return itemA[1].toLowerCase() > itemB[1].toLowerCase() ? 1 : -1
            }
          }),
        ])
        setAscending(true)
      } else {
        setBookings([
          ...bookings.sort((a, b) => {
            let itemA, itemB
            if (keyItem) {
              itemA = Object.entries(a).find(key => key[0] === keyItem)
              itemB = Object.entries(b).find(key => key[0] === keyItem)
              return itemA[1] < itemB[1] ? 1 : -1
            } else {
              itemA = Object.entries(a.patient).find(key => key[0] === item)
              itemB = Object.entries(b.patient).find(key => key[0] === item)
              return itemA[1].toLowerCase() < itemB[1].toLowerCase() ? 1 : -1
            }
          }),
        ])
        setAscending(false)
      }
    }
  }

  const sortCallColumns = e => {
    let item = e.target.dataset.key

    switch (item) {
      case 'phone':
        sortCallItems('phone')
        break
      case 'started':
        sortCallItems('started')
        break
      case 'duration':
        sortCallItems('duration')
        break
      default:
        break
    }

    function sortCallItems(item) {
      if (!ascending) {
        setCalls([
          ...calls.sort((a, b) => {
            let itemA, itemB
            itemA = Object.entries(a).find(key => key[0] === item)
            itemB = Object.entries(b).find(key => key[0] === item)
            return itemA[1] > itemB[1] ? 1 : -1
          }),
        ])
        setAscending(true)
      } else {
        setCalls([
          ...calls.sort((a, b) => {
            let itemA, itemB
            itemA = Object.entries(a).find(key => key[0] === item)
            itemB = Object.entries(b).find(key => key[0] === item)
            return itemA[1] < itemB[1] ? 1 : -1
          }),
        ])
        setAscending(false)
      }
    }
  }

  // Funcion to match currrent call with duplicates
  const matchDuplicates = (call, arr) => {
    let doubles = []
    arr.map(duplicateCalls =>
      duplicateCalls.map(duplicateCall =>
        duplicateCall.phone === call.phone ? doubles.push(duplicateCall) : null
      )
    )

    return doubles
  }

  const renderTooltip = (callsArray, call) => {
    return (
      <Tooltip
        id={`recurring-call-${call.id}`}
        place="top"
        effect="solid"
        type="dark"
        multiline={true}
      >
        Har även ringt vid följande tillfällen: <br />
        <ul>
          {callsArray.map(duplicateCall =>
            duplicateCall.phone === call.phone ? (
              <li>{format(new Date(duplicateCall.started), 'yyyy-MM-dd | HH:mm')}</li>
            ) : null
          )}
        </ul>
      </Tooltip>
    )
  }

  useEffect(() => {
    if ((!invoice && !isAuthError) || invoice?.id.toString() !== id) {
      fetchInvoice(id)
    }

    if (invoice) {
      setBookings(invoice.bookings.sort((a, b) => (a.date > b.date ? 1 : -1)))

      // Get unique calls and store them in state
      let uniqueCalls = []
      invoice.calls.forEach(call => {
        let index = uniqueCalls.findIndex(i => i.phone === call.phone && i.id !== call.id)
        if (index === -1) {
          uniqueCalls.push(call)
        }
      })
      setCalls(uniqueCalls)

      // Group duplicates by phone key
      let duplicates = invoice.calls.filter(call => !uniqueCalls.includes(call))
      let groupBy = (calls, key) => {
        return calls.reduce((arr, item) => {
          ;(arr[item[key]] = arr[item[key]] || []).push(item)
          return arr
        }, {})
      }
      let groupedDuplicates = groupBy(duplicates, 'phone')
      setDuplicateCalls(Object.values(groupedDuplicates))

      let { Period } = invoice
      let date = `${Period.year}-${Period.month}`
      setPeriod({
        year: format(new Date(date), 'y'),
        month: format(new Date(date), 'MMMM', { locale: sv }),
      })
    }
  }, [fetchInvoice, id, invoice, isAuthError])

  useEffect(() => {
    if (bookingSearch !== '') {
      filterBookings(bookings)
    }

    if (callSearch !== '') {
      filterCalls(calls)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingSearch, filterBookings, callSearch, filterCalls])

  if (!invoice)
    return (
      <>
        <Header />
        <PageContainer>
          <Container>
            <Title>Laddar fakturaunderlag..</Title>
            {isAuthError ? <ExpirationModal authError={authErrorMessage} /> : null}
          </Container>
        </PageContainer>
      </>
    )

  if (invoice.status === 'ProposalApproved') {
    return (
      <>
        <Header />
        <PageContainer>
          <Container>
            <Title>Denna månads fakturaunderlag har blivit godkänt. Tack så mycket!</Title>
          </Container>
        </PageContainer>
      </>
    )
  }

  return (
    <>
      <ContestModal
        open={contest !== null}
        unContest={unContest}
        onClose={() => setContest(null)}
        onSubmit={onContest}
      />
      {isAuthError ? <ExpirationModal authError={authErrorMessage} /> : null}
      <Header />
      <PageContainer>
        <Container>
          <Title>
            Fakturaunderlag för {period?.month} {period?.year}
          </Title>
          <DataContainer onSubmit={e => e.preventDefault()}>
            <>
              <DataHead>
                <DataTitle>Bokningar via Tandläkare.se</DataTitle>
                <InputContainer>
                  <InvoiceInput
                    placeholder={'Sök bokning...'}
                    value={bookingSearch}
                    onChange={e => setBookingSearch(e.target.value.toLocaleLowerCase())}
                  />
                  <InputLabel data-type="booking" onClick={onClearInput}>
                    x
                  </InputLabel>
                </InputContainer>
              </DataHead>
              <DataTable>
                <thead>
                  <tr onClick={sortBookingColumns}>
                    <OuterLeftTableHeader id="date">Datum</OuterLeftTableHeader>
                    <th id="name">Namn</th>
                    <th id="ssn">Personnummer</th>
                    <th id="phone">Telefon</th>
                    <th id="email">Email</th>
                    <th id="type">Behandlingstyp</th>
                    <OuterRightTableHeader></OuterRightTableHeader>
                  </tr>
                </thead>
                {bookings && bookings.length > 0 ? (
                  <tbody>
                    {bookings.map(booking => (
                      <tr
                        key={booking.id}
                        className={`${booking.contest?.contested ? 'contested' : ''} ${
                          booking.fromSearch && bookingSearch !== '' ? 'from-search' : ''
                        }`}
                      >
                        <td>
                          {booking.rescheduled ? (
                            <ClockIcon data-tip data-for={`booking-rescheduled-${booking.id}`} />
                          ) : (
                            ''
                          )}
                          {booking.rescheduled ? (
                            <Tooltip
                              id={`booking-rescheduled-${booking.id}`}
                              place="top"
                              effect="solid"
                              type="dark"
                            >
                              "Ombokad från föregående period"
                            </Tooltip>
                          ) : (
                            ''
                          )}{' '}
                          {format(new Date(booking.date), 'yyyy-MM-dd')}
                        </td>
                        <td>{booking.patient.name}</td>
                        <td>{booking.patient.ssn}</td>
                        <td>
                          {booking.patient.phone.slice(0, 2).includes('00')
                            ? '+' + booking.patient.phone.substring(2)
                            : booking.patient.phone}
                        </td>
                        <td>{booking.patient.email}</td>
                        <td>
                          <TreatmentType>
                            {booking.type.toLowerCase() === 'sistaminuten' ? (
                              <ClockIcon data-tip data-for={`booking-type-${booking.id}`} />
                            ) : (
                              <CalendarIcon data-tip data-for={`booking-type-${booking.id}`} />
                            )}
                          </TreatmentType>
                          <Tooltip
                            id={`booking-type-${booking.id}`}
                            place="top"
                            effect="solid"
                            type="dark"
                          >
                            {booking.type}
                          </Tooltip>{' '}
                          {booking.treatment_name}
                        </td>
                        <td>
                          {booking.contest?.contested ? (
                            <>
                              <UncontestButton onClick={e => openModal(e)} id={booking.id}>
                                <span>Ångra</span>
                              </UncontestButton>
                              {booking.id === parseInt(openedRow) && (
                                <ConfirmModal
                                  type="booking"
                                  setOpenedRow={setOpenedRow}
                                  itemId={booking.id}
                                  fetchInvoice={fetchInvoice}
                                  paramId={id}
                                  page={'invoice'}
                                />
                              )}
                            </>
                          ) : (
                            <ContestButton
                              onClick={() =>
                                setContest({
                                  id: booking.id,
                                  type: 'booking',
                                })
                              }
                            >
                              Neka
                            </ContestButton>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <EmptyTable
                    text={'Just nu har du inga bokningar som behöver godkännas'}
                    colSpan={7}
                  />
                )}
              </DataTable>

              <DataHead>
                <DataTitle>Samtal via Tandläkare.se</DataTitle>
                <InputContainer>
                  <InvoiceInput
                    placeholder={'Sök samtal...'}
                    value={callSearch}
                    onChange={e => setCallSearch(e.target.value)}
                  />
                  <InputLabel data-type="call" onClick={onClearInput}>
                    x
                  </InputLabel>
                </InputContainer>
              </DataHead>
              <DataTable>
                <thead>
                  <tr onClick={sortCallColumns}>
                    <OuterLeftTableHeader data-key="phone">Telefonnummer</OuterLeftTableHeader>
                    <th data-key="started">Tidpunkt</th>
                    <th data-key="duration">Samtalets längd</th>
                    <OuterRightTableHeader></OuterRightTableHeader>
                  </tr>
                </thead>

                {calls?.length > 0 ? (
                  <tbody>
                    {calls.map(call => (
                      <tr
                        key={call.id}
                        className={`${call.contest?.contested ? 'contested' : ''} ${
                          call.fromSearch && callSearch !== '' ? 'from-search' : ''
                        }`}
                      >
                        <td>{call.phone}</td>
                        <td>
                          {format(new Date(call.started), 'yyyy-MM-dd | HH:mm')}
                          {''}
                          {duplicateCalls.map(callsArray => {
                            let hasDuplicates = false
                            callsArray.forEach(
                              duplicateCall =>
                                (hasDuplicates = duplicateCall.phone.includes(call.phone))
                            )
                            return hasDuplicates ? (
                              <>
                                <RecurringIcon data-tip data-for={`recurring-call-${call.id}`} />
                                {renderTooltip(callsArray, call)}
                              </>
                            ) : (
                              ''
                            )
                          })}
                        </td>
                        <td>{formattedTime(call.duration)}</td>
                        <td>
                          {call.contest?.contested ? (
                            <>
                              <UncontestButton onClick={e => openModal(e)} id={call.id}>
                                <span>Ångra</span>
                              </UncontestButton>
                              {call.id === parseInt(openedRow) && (
                                <ConfirmModal
                                  type="call"
                                  calls={matchDuplicates(call, duplicateCalls)}
                                  setOpenedRow={setOpenedRow}
                                  itemId={call.id}
                                  fetchInvoice={fetchInvoice}
                                  paramId={id}
                                  page={'invoice'}
                                />
                              )}
                            </>
                          ) : (
                            <ContestButton
                              onClick={() => {
                                setContest({
                                  id: call.id,
                                  type: 'call',
                                })
                              }}
                            >
                              Neka
                            </ContestButton>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <EmptyTable
                    text={'Just nu har du inga samtal som behöver godkännas'}
                    colSpan={4}
                  />
                )}
              </DataTable>
              <ApproveButton onClick={onApprove}>Godkänn för fakturering</ApproveButton>
            </>
          </DataContainer>
        </Container>
      </PageContainer>
    </>
  )
}

export default InvoicePage

const InvoiceInput = styled(Input)`
  &::placeholder {
    font-size: 16px;
  }
`

const ApproveButton = styled(Button)`
  color: #d70040;
  transition: all 0.2s ease;
  text-align: center;
  opacity: 1;
  background: #3cd487;
  border-color: #3cd487;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  padding: 10px 0;
  margin-top: 20px;
  height: 50px;
  font-size: 20px;
`
const RecurringIcon = styled(IoMdRefresh)`
  stroke-width: 18px;
`
