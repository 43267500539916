import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { PageContainer, Container, Title, Button } from '../components/Shared'
import Header from '../components/Header'
import { LogoutButton } from '../components/buttons'

const ApprovedPage = () => {
  const history = useHistory()

  const navigateToHomepage = () => {
    history.replace('/')
  }

  return (
    <>
      <Header />
      <PageContainer>
        <Container>
          <Title>
            Tack!
            <br /> Ditt fakturaunderlag är nu godkänt.
          </Title>
          <ButtonWrap>
            <HomeButton onClick={navigateToHomepage}>Till startsidan</HomeButton>
          </ButtonWrap>
          <ButtonWrap>
            <LogoutButton />
          </ButtonWrap>
        </Container>
      </PageContainer>
    </>
  )
}

export default ApprovedPage

const ButtonWrap = styled.div`
  width: 100%;
  text-align: center;
`

const HomeButton = styled(Button)`
  background: #fff;
  border-radius: 4px;
  color: #583091;
  margin-top: 20px;
  width: 50%;
`
