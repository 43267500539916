import styled from 'styled-components/macro'
import { rgba } from 'polished'
import ReactTooltip from 'react-tooltip'
import { FiClock } from 'react-icons/fi'
import { IoIosCalendar } from 'react-icons/io'

import device from '../utils/breakpoints'
import { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;

  ${device.desktop} {
    width: 1280px;
    min-width: 1280px;
  }
`

export const Title = styled.h1`
  font-weight: 700;
  color: #fff;
  text-align: center;
  font-size: 36px;
`

export const Subtitle = styled.h3`
  font-size: 22px;
  text-align: center;
  color: ${props => (props.color === 'black' ? '#222' : '#fff')};

  ${device.tablet} {
    font-size: 28px;
  }
`

export const DataTable = styled.table`
  border-spacing: 0 2px;
  margin-bottom: 60px;

  &:last-of-type {
    margin-bottom: 0 !important;
  }

  thead {
    background: #583091;
    position: sticky;
    top: 0;
    color: #fff;
    z-index: 10;

    tr {
      cursor: pointer;
      height: 60px;
    }
  }

  tr {
    text-align: left;
    height: 40px;
    transition: all 0.2s ease;
  }

  tbody {
    tr {
      background: ${rgba('#E2EDEE', 0.2)};

      &:hover {
        background: ${rgba('#E2EDEE', 0.6)};

        button {
          opacity: 1;
        }
      }

      &.from-search {
        background: ${rgba('#3cd487', 0.2)};
      }

      &.contested {
        box-shadow: none;
        color: ${rgba('#222', 0.4)};
        background: ${rgba('#d70040', 0.1)};
      }
    }
  }

  td,
  th {
    padding: 0 10px;
  }

  td {
    &:last-child {
      text-align: right;
      width: 60px;
    }
  }
`

export const DataHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`

export const Button = styled.button`
  background: #583091;
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  color: #fff;
  height: 35px;
  font-weight: 500;
  letter-spacing: 1px;
  outline: none;
  line-height: 1;
  font-size: 14px;
`

export const InputLabel = styled.span`
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${rgba('#000', 0.1)};
  border-radius: 5px;
`

export const Input = styled.input`
  border: none;
  padding: 20px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  background: transparent;
  flex: 1;
  font-size: 20px;
  color: #222;
`

export const DataContainer = styled.form`
  width: 100%;
  min-height: 500px;
  background: #fff;
  margin: 60px auto 0;
  box-shadow: 0 0 6px 4px ${rgba('#000', 0.1)};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 100px;
`

export const DataTitle = styled.h2`
  margin: 0;
`

export const PageContainer = styled.div``

export const OuterLeftTableHeader = styled.th`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`

export const OuterRightTableHeader = styled.th`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`

export const CenteredTh = styled.th`
  text-align: center;
`

export const InputContainer = styled.div`
  height: 50px;
  width: 400px;
  border-radius: 5px;
  background: ${rgba('#583091', 0.2)};
  display: flex;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;

  ${props =>
    props.error &&
    css`
      background: ${rgba('red', 0.05)};
    `}
`

export const TreatmentType = styled.span`
  cursor: pointer;
  font-size: 14px;
`
export const Tooltip = styled(ReactTooltip)``
export const ClockIcon = styled(FiClock)``
export const CalendarIcon = styled(IoIosCalendar)``
