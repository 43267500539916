import styled from 'styled-components'

const EmptyTable = ({ text, colSpan }) => {
  return (
    <tbody>
      <tr>
        <Message colSpan={colSpan}>{text}</Message>
      </tr>
    </tbody>
  )
}
export default EmptyTable

const Message = styled.td`
  padding: 10px;
  font-size: 16px;
  text-align: center !important;
`
