import { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import globalContext from '../../global-context'
import { Button } from '../Shared'

const ConfirmModal = ({
  type,
  calls,
  setOpenedRow,
  itemId,
  fetchInvoice,
  fetchBookings,
  paramId,
  page,
}) => {
  const { contestBooking, contestCall, reopenInvoice } = useContext(globalContext)
  const modalRef = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  })

  const handleClickOutside = e => !modalRef.current?.contains(e.target) && setOpenedRow(null)

  const handleConfirm = () => {
    if (type === 'invoice') {
      reopenInvoice(itemId)
      setOpenedRow(null)
    } else {
      handleUncontestItem()
    }
  }

  const handleUncontestItem = () => {
    if (type === 'call' && calls.length > 0) {
      calls.map(async call => {
        await contestCall({
          id: call.id,
          reason: '',
          contest: false,
        })
      })
    }

    let uncontestRow =
      type === 'booking'
        ? contestBooking({ id: itemId, reason: '', contest: false })
        : contestCall({ id: itemId, reason: '', contest: false })
    setOpenedRow(null)
    uncontestRow.then(() => (page === 'invoice' ? fetchInvoice(paramId) : fetchBookings(paramId)))
  }

  return (
    <Container ref={modalRef}>
      <SpeechArrow></SpeechArrow>
      <InnerContainer>
        <span>Är du säker?</span>
        <ButtonContainer>
          <ConfirmButton onClick={handleConfirm} color="green">
            Ja
          </ConfirmButton>
          <ConfirmButton onClick={() => setOpenedRow(null)} color="red">
            Nej
          </ConfirmButton>
        </ButtonContainer>
      </InnerContainer>
    </Container>
  )
}

export default ConfirmModal

const Container = styled.div`
  height: 100%;
  position: relative;
  text-align: center;
`

const InnerContainer = styled.div`
  width: 150px;
  background: #fff;
  box-shadow: 0 0 6px 4px ${rgba('#000', 0.1)};
  border-radius: 10px;
  color: rgba(34, 34, 34, 0.8);
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  overflow: hidden;
  position: absolute;
  transition: all 0.2s ease;
  z-index: 2;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.75rem;
`

const ConfirmButton = styled(Button)`
  background: ${({ color }) => (color === 'green' ? rgba('#3cd487', 0.8) : rgba('#d70040', 0.8))};
  border: none;
  border-radius: 4px;
  max-width: 75px;
  width: 100%;
  height: 40px;
  transition: background 0.2s ease;

  &:hover {
    background: ${({ color }) => (color === 'green' ? '#3cd487' : '#d70040')};
  }

  &:nth-child(1) {
    margin-right: 0.3rem;
  }
`

const SpeechArrow = styled.div`
  position: relative;
  background: #fff;

  &:after {
    border: 10px solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    border-left: 0;
    content: '';
    margin-top: -10px;

    position: absolute;
    top: 0;
  }
`
