import { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import warning from '../../assets/images/warning.svg'

import { Button } from '../Shared'

const ContestModal = ({ onSubmit = () => {}, onClose = () => {}, open = false }) => {
  const [reason, setReason] = useState('NO_SHOW')
  const [description, setDescription] = useState('')
  const [other, setOther] = useState(false)
  const containerRef = useRef()
  const inputRef = useRef()

  const submitForm = async e => {
    e.preventDefault()

    await onSubmit({
      reason,
      description,
    })

    setReason('NO_SHOW')
    setDescription('')
  }

  const onContainerClick = e => {
    if (e.target === containerRef.current) {
      onClose()
      setReason('NO_SHOW')
    }
  }

  const onCloseClick = e => {
    e.preventDefault()
    onClose()
    setReason('NO_SHOW')
  }

  const handleReason = reason => {
    setReason(reason)
    if (reason === 'OTHER') {
      setOther(true)
      setDescription('')
    } else {
      setOther(false)
    }
  }

  useEffect(() => {
    if (other) inputRef.current.focus()
  }, [other])

  return (
    <Container open={open} ref={containerRef} onClick={onContainerClick}>
      <Modal onSubmit={submitForm}>
        <CloseButtonContainer>
          <CloseButton onClick={onCloseClick}>x</CloseButton>
        </CloseButtonContainer>
        {reason !== 'OTHER' && <Title>Av vilken anledning vill du neka denna bokning?</Title>}

        <ReasonSelect value={reason} onChange={e => handleReason(e.target.value)}>
          <optgroup label="Välj en anledning" />
          <option value="RESCHEDULED">Ombokad till senare</option>
          <option value="NO_SHOW">Dök inte upp</option>
          <option value="EXISTING_PATIENT">Befintlig patient</option>
          <option value="OTHER">Annat</option>
        </ReasonSelect>
        <MessageWrap>
          {reason === 'NO_SHOW' && (
            <>
              <WarningIcon />
              <Message>
                Om patientens tid ombokats till kommande månad - välj "Ombokad till senare"
              </Message>
            </>
          )}
        </MessageWrap>

        {other && reason === 'OTHER' && (
          <ReasonInput
            ref={inputRef}
            placeholder="Varför vill du neka denna bokning?"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        )}

        <DenyButton disabled={reason === 'OTHER' && !description}>Neka</DenyButton>
      </Modal>
    </Container>
  )
}

export default ContestModal

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${rgba('#000', 0.6)};
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;

  ${props =>
    props.open &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`

const Modal = styled.form`
  width: 400px;
  background: #fff;
  margin: 60px auto 0;
  box-shadow: 0 0 6px 4px ${rgba('#000', 0.1)};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 100px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`

const Title = styled.div`
  color: #484848;
  font-size: 20px;
  text-align: center;
  padding: 15px;
`

const MessageWrap = styled.div`
  display: flex;
  height: 40px;
  padding: 0 20px;
`

const Message = styled.div`
  color: #484848;
  font-size: 14px;
  text-align: center;
  padding: 10px 10px;
  text-align: left;
`

const DenyButton = styled(Button)`
  background: #d70040;
  border: none;
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  transition: all 0.2s ease;

  ${props =>
    props.disabled &&
    css`
      background: ${rgba('#d70040', 0.5)};
      pointer-events: none;
    `}
`

const ReasonSelect = styled.select`
  outline: none;
  padding: 20px;
  font-size: 20px;
  color: #484848;
  border: 2px solid #484848;
  border-radius: 5px;
`

const ReasonInput = styled.textarea`
  width: 100%;
  height: 150px;
  resize: none;
  border: none;
  margin-top: 20px;
  padding: 20px;
  outline: none;
  box-sizing: border-box;
  background: transparent;
  font-size: 20px;
  color: #222;
  background: ${rgba('#E2EDEE', 0.8)};
`

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
`

const CloseButton = styled.button`
  border: 0;
  cursor: pointer;

  background: transparent;
  font-size: 18px;
`

const WarningIcon = styled.div`
  display: inline-block;
  width: 55px;
  height: 50px;
  background: url(${warning}) no-repeat center;
`
