const size = {
  xs: '375px',
  sm: '768px',
  lg: '1200px',
}

const device = {
  mobile: `@media screen and (min-width: ${size.xs})`,
  tablet: `@media screen and (min-width: ${size.sm})`,
  desktop: `@media screen and (min-width: ${size.lg})`,
}

export default device
