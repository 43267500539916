import { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { format, subMonths, subYears } from 'date-fns'

import { PageContainer, Container, Title } from '../components/Shared'
import Header from '../components/Header'
import LoginForm from '../components/LoginForm'

import globalContext from '../global-context'

const LoginPage = () => {
  const history = useHistory()
  const location = useLocation()
  const { user, login, loginError, setLoginError, fetching } = useContext(globalContext)

  const today = new Date()
  const month = format(subMonths(today, 1), 'M')
  const year = month === '12' ? format(subYears(today, 1), 'y') : format(today, 'y')

  const onSubmit = data => {
    login(data)
  }

  const onChange = () => {
    setLoginError(null)
  }

  useEffect(() => {
    let { from } = location.state || { from: { pathname: '/' } }

    if (user) {
      // base64 decode JWT token
      let tokens = user.token.split('.')
      let parsedToken = JSON.parse(window.atob(tokens[1]))
      let role = parsedToken.role
      role === 'admin' ? history.push(`/admin/${year}-${month}`) : history.push(from)
    }
  }, [user, history, month, year, location.state])

  return (
    <>
      <Header />
      <PageContainer>
        <Container>
          <Title>Logga in för att hantera fakturaunderlag</Title>
          <LoginForm
            onSubmit={onSubmit}
            onChange={onChange}
            error={loginError}
            fetching={fetching}
          />
        </Container>
      </PageContainer>
    </>
  )
}

export default LoginPage
