import styled from 'styled-components'

import { Button } from '../Shared'

const UncontestButton = ({ children, onClick, id }) => {
  return (
    <UncontestBtn id={id} onClick={onClick}>
      {children}
    </UncontestBtn>
  )
}

export default UncontestButton

const UncontestBtn = styled(Button)`
  border: 1px solid #d70040;
  color: #fff;
  background: #d70040;
  opacity: 0.8;
  position: relative;
  padding: 10px 0;
  transition: all 0.2s ease;
  text-align: center;
  width: 80px;
`
