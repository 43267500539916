import { useContext, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import { rgba } from 'polished'

import { PageContainer, Container, Title, Button } from '../components/Shared'
import Header from '../components/Header'
import Loading from '../components/Loading'
import { ExpirationModal } from '../components/modals'
import { LogoutButton } from '../components/buttons'

import globalContext from '../global-context'

const HomePage = () => {
  const { user, fetchActiveInvoice, isAuthError, authErrorMessage, fetchClinics } =
    useContext(globalContext)
  const [activeInvoices, setActiveInvoices] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [clinics, setClinics] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (!user) {
      history.replace('/login')
    }
  }, [user, history])

  useEffect(() => {
    fetchClinics().then(clinics => setClinics(clinics))

    fetchActiveInvoice()
      .then(data => {
        let proposedInvoices = data?.filter(invoice => invoice.status === 'Proposed')
        setActiveInvoices(proposedInvoices)
      })
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header />
      <PageContainer>
        <Container>
          <Title>Tandläkare.se - Bokningar och samtal</Title>
          {isAuthError ? <ExpirationModal authError={authErrorMessage}></ExpirationModal> : null}
          {isLoading ? <Loading /> : null}
          {activeInvoices && !isLoading ? (
            <HomePageContainer>
              <LoggedInText>
                Inloggad som <span className="clinic-name">{user.username}</span>
              </LoggedInText>
              <section>
                <Bookings>
                  <h3>Bokningar</h3>
                  <p>Klicka på länken för att hantera denna månadens bokningar.</p>

                  {clinics?.map(clinic => {
                    return (
                      <LinkButton key={clinic.id}>
                        <Link to={{ pathname: `/bookings/${clinic.id}` }}>{clinic.name}</Link>
                      </LinkButton>
                    )
                  })}
                </Bookings>

                {activeInvoices.length === 0 ? (
                  <Invoices>
                    <h3>Fakturaunderlag</h3>
                    <p className="italic">
                      Just nu har du inget fakturaunderlag som behöver godkännas.
                    </p>
                    <p>
                      I slutet av varje månad sammanställs alla månadens bokningar i ett
                      fakturaunderlag som du sedan hanterar här.
                    </p>
                  </Invoices>
                ) : (
                  <Invoices>
                    <h3>Fakturaunderlag</h3>
                    <p>
                      Det finns bokningar och samtal för denna månad som väntar på godkännande.
                      <br />
                      Klicka på länk för att komma till fakturaunderlaget.
                    </p>

                    {activeInvoices.map(invoice => {
                      return (
                        <LinkButton key={invoice.id}>
                          <Link to={{ pathname: `/invoice/${invoice.id}` }}>
                            {invoice.clinic.name}
                          </Link>
                        </LinkButton>
                      )
                    })}
                  </Invoices>
                )}
              </section>
              <LogoutButton />
            </HomePageContainer>
          ) : null}
        </Container>
      </PageContainer>
    </>
  )
}

export default HomePage

const LinkButton = styled(Button)`
  width: 50%;
  border-radius: 4px;
  transition: background 0.2s ease;
  margin-top: 20px;

  &:hover {
    background: #442770;
  }

  a {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
    height: inherit;
    text-decoration: none;
    width: 100%;
  }
`

const HomePageContainer = styled.div`
  background: #fff;
  box-shadow: 0 0 6px 4px ${rgba('#000', 0.1)};
  height: 100%;
  width: 100%;
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  section {
    padding: 24px 12px;
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 12px;
      min-height: 200px;
      width: 75%;
    }
  }

  p {
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
    margin: 0;

    &.italic {
      font-style: italic;
      font-size: 16px;
    }

    > .clinic-name {
      color: #583091;
      font-weight: 700;
    }
  }
`

const Bookings = styled.div`
  background: ${rgba('#583091', 0.2)};
  margin-bottom: 18px;
`

const Invoices = styled.div`
  background: ${rgba('#583091', 0.2)};
`
const LoggedInText = styled.p`
  padding-top: 18px;
`
