import { PageContainer, Container, Title } from '../components/Shared'
import Header from '../components/Header'

const NotFoundPage = () => {
  return (
    <>
      <Header />
      <PageContainer>
        <Container>
          <Title>Sidan du söker finns tyvärr inte! :(</Title>
        </Container>
      </PageContainer>
    </>
  )
}

export default NotFoundPage
