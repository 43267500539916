import styled from 'styled-components'

const Loading = () => {
  return (
    <>
      <LoadingSpinner className="load">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingSpinner>
    </>
  )
}

export default Loading

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 80px;
  margin: 0 auto;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: load 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
