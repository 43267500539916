import { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { rgba } from 'polished'

import { Button } from '../components/Shared'

const LoginForm = ({ onSubmit = () => {}, onChange, error, fetching }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const submitForm = e => {
    e.preventDefault()

    onSubmit({
      username,
      password,
    })
  }

  return (
    <LoginFormContainer onSubmit={submitForm} onChange={() => error && onChange()}>
      <InputContainer error={!!error}>
        <InputLabel>Användare</InputLabel>
        <Input value={username} onChange={e => setUsername(e.target.value)} />
      </InputContainer>
      <InputContainer error={!!error}>
        <InputLabel>Lösenord</InputLabel>
        <Input type="password" value={password} onChange={e => setPassword(e.target.value)} />
      </InputContainer>
      <LoginButton type="submit" disabled={!!error || !username || !password || fetching}>
        {fetching ? 'Loggar in...' : 'Logga in'}
      </LoginButton>
      <ErrorText>{error}</ErrorText>
    </LoginFormContainer>
  )
}

export default LoginForm

const LoginFormContainer = styled.form`
  width: 400px;
  background: #fff;
  margin: 60px auto 0;
  box-shadow: 0 0 6px 4px ${rgba('#000', 0.1)};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 100px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`

const InputContainer = styled.div`
  height: 60px;
  width: 100%;
  background: ${rgba('#E2EDEE', 0.8)};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;

  ${props =>
    props.error &&
    css`
      background: ${rgba('red', 0.05)};
    `}
`

const InputLabel = styled.span`
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${rgba('#000', 0.1)};
`

const Input = styled.input`
  border: none;
  padding: 20px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  background: transparent;
  flex: 1;
  font-size: 20px;
  color: #222;
`

const LoginButton = styled(Button)`
  background: #583091;
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  transition: all 0.2s ease;

  ${props =>
    props.disabled &&
    css`
      background: ${rgba('#583091', 0.5)};
      pointer-events: none;
    `}
`

const ErrorText = styled.p`
  color: red;
  position: absolute;
  bottom: 90px;
  margin: 0;
`
