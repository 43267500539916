import { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import globalContext from '../../global-context'
import { Button } from '../Shared'

const ExpirationModal = ({ authError }) => {
  const [hide, setHide] = useState(false)
  const { setUser, setAuthErrorMessage } = useContext(globalContext)

  useEffect(() => {
    if (!authError && hide) {
      setHide(false)
    }
  }, [hide, authError])

  const handleBtnClick = () => {
    authError.code !== 400 ? setUser(null) : setHide(true)
    setAuthErrorMessage(null)
  }

  return authError && !hide ? (
    <Container>
      <Modal>
        <Text>{authError?.message}</Text>
        <ExpButton onClick={handleBtnClick}>OK</ExpButton>
      </Modal>
    </Container>
  ) : null
}

export default ExpirationModal

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${rgba('#000', 0.4)};
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
`

const Text = styled.p`
  color: #222;
  font-size: 20px;
  text-align: center;
`

const Modal = styled.div`
  width: 50vh;
  height: 200px;
  background: #fff;
  border-radius: 10px;
  color: rgba(34, 34, 34, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  position: absolute;
  transition: all 0.2s ease;
  z-index: 2;
`

const ExpButton = styled(Button)`
  background: #583091;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  width: 50%;
  height: 60px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0 auto;
`
