import { useEffect, useContext, useState, Fragment, useRef } from 'react'
import { Container, PageContainer, Subtitle, Title, Tooltip } from '../components/Shared'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { rgba } from 'polished'
import axios from 'axios'
import { MdContentCopy } from 'react-icons/md'

import globalContext from '../global-context'
import Header from '../components/Header'
import Loading from '../components/Loading'
import { ExpirationModal, ConfirmModal } from '../components/modals'
import device from '../utils/breakpoints'
import checkbox from '../assets/images/checkbox.svg'
import robot from '../assets/images/robot.svg'
import { Button } from '../components/Shared'

const AdminPage = () => {
  const { fetchInternalData, authErrorMessage, isAuthError, user, invoiceId } =
    useContext(globalContext)
  const { date } = useParams()
  const [invoiceData, setInvoiceData] = useState([])
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [activeRow, setActiveRow] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [copiedRow, setCopiedRow] = useState(null)
  const [openedRow, setOpenedRow] = useState(null)

  const copyTextRef = useRef()

  const INVOICE_INTERNAL_API = process.env.REACT_APP_INVOICE_INTERNAL_API

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchInternalData(date)
        setInvoiceData(
          data?.sort((a, b) =>
            a.clinic?.name.toUpperCase() > b.clinic?.name.toUpperCase() ? 1 : -1
          )
        )
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
    setYear(format(new Date(date), 'Y'))
    setMonth(format(new Date(date), 'MMMM', { locale: sv }))
    // removed fetchInternalData from dependency to avoid loop when user token is expired
    //eslint-disable-next-line
  }, [date, invoiceId])

  const handleCopyRowClick = e => {
    if (e.target.className === 'checkbox') {
      return
    }

    let row = invoiceData.find(row => row.id === parseInt(e.currentTarget.id))
    let { approved_bookings, approved_calls } = row.summary

    // Get x, y and width to calculate text ref position
    let x = e.clientX
    let y = e.pageY
    let windowSize = window.outerWidth
    let elementWidth = e.target.getBoundingClientRect().width

    // Copy total when click on button, else copy URL for target invoice
    let targetIsButton = e.target.tagName === 'BUTTON' || e.target.parentNode.tagName === 'BUTTON'
    if (targetIsButton) {
      setCopiedRow(null)
      let totalBookingsAndCalls = parseInt(approved_bookings + approved_calls)
      navigator.clipboard.writeText(totalBookingsAndCalls)
    } else {
      setCopiedRow(row.id)
      copyTextRef.current.classList.add('show')
      copyTextRef.current.style.top = `calc(${y}px - 230px)`
      copyTextRef.current.style.left =
        windowSize === 1920 ? `calc(${x}px - ${elementWidth}px)` : `${x}px`
      let invoiceUrl = `${window.location.origin}/invoice/${row.id}`
      navigator.clipboard.writeText(invoiceUrl)

      setTimeout(() => {
        copyTextRef.current.classList.remove('show')
      }, 1000)
    }
  }

  const onHandledButtonClick = async e => {
    let invoiceId = e.currentTarget.dataset.id

    try {
      await axios.post(`${INVOICE_INTERNAL_API}/invoices/${invoiceId}`, null, {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const openModal = id => {
    setOpenedRow(id)
  }

  return (
    <>
      <Header />
      <PageContainer>
        <Container>
          <Title>Admin</Title>
          <Subtitle color="white">
            Fakturaunderlag för {month} {year}
          </Subtitle>
          {isAuthError ? <ExpirationModal authError={authErrorMessage}></ExpirationModal> : null}
          {isLoading ? (
            <Loading />
          ) : (
            <InvoiceTableContainer>
              <CopyText ref={copyTextRef}>Länk kopierad!</CopyText>
              <InvoiceTable>
                <thead>
                  <tr>
                    <td>Klinik</td>
                    <td>Status</td>
                    <td>Bokningar</td>
                    <td>Samtal</td>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData?.map(row => {
                    let { id, clinic, status, summary, auto_approved } = row
                    let isProposalApproved = status === 'ProposalApproved'
                    let isInvoiced = status === 'Invoiced'
                    return (
                      <Fragment key={id}>
                        <tr
                          className={isInvoiced ? 'handled' : copiedRow === id ? 'copied' : ''}
                          id={id}
                          onClick={handleCopyRowClick}
                          data-tip
                          data-for="copy-invoice-link"
                        >
                          <td>{clinic?.name.slice(0, 1).toUpperCase() + clinic?.name.slice(1)}</td>
                          <td>
                            {isProposalApproved || isInvoiced ? (
                              <StatusColor approved>
                                Godkänd{' '}
                                {auto_approved && <Robot data-tip data-for="auto-approve-tip" />}
                                <Tooltip
                                  id="auto-approve-tip"
                                  place="top"
                                  effect="solid"
                                  type="dark"
                                >
                                  Automatiskt godkänd av systemet
                                </Tooltip>
                              </StatusColor>
                            ) : (
                              <StatusColor>Skickat till kund</StatusColor>
                            )}
                          </td>
                          <td>
                            <TypeOfSummaryContainer>
                              <TypeOfSummary type="approved">Godkända: </TypeOfSummary>
                              {summary?.approved_bookings}
                            </TypeOfSummaryContainer>
                            <TypeOfSummaryContainer>
                              <TypeOfSummary type="contested">Nekade: </TypeOfSummary>
                              {parseInt(summary?.total_bookings - summary?.approved_bookings)}
                            </TypeOfSummaryContainer>
                            <TypeOfSummaryContainer>
                              <TypeOfSummary type="total">Total: </TypeOfSummary>
                              {summary?.total_bookings}
                            </TypeOfSummaryContainer>
                          </td>
                          <td>
                            <TypeOfSummaryContainer>
                              <TypeOfSummary type="approved">Godkända: </TypeOfSummary>
                              {summary?.approved_calls}
                            </TypeOfSummaryContainer>
                            <TypeOfSummaryContainer>
                              <TypeOfSummary type="contested">Nekade: </TypeOfSummary>
                              {parseInt(summary?.total_calls - summary?.approved_calls)}
                            </TypeOfSummaryContainer>
                            <TypeOfSummaryContainer>
                              <TypeOfSummary type="total">Total: </TypeOfSummary>
                              {summary?.total_calls}
                            </TypeOfSummaryContainer>
                          </td>
                          <td>{parseInt(summary?.approved_calls + summary?.approved_bookings)}</td>
                          <ButtonTd>
                            <CopyButton
                              onClick={() => setActiveRow(row)}
                              active={activeRow?.id === row.id}
                            >
                              <MdContentCopy />
                            </CopyButton>
                            <CheckButtonContainer>
                              <div data-id={id} onClick={onHandledButtonClick}>
                                <label className="checkbox" htmlFor={`checkbox-${id}`}>
                                  Hanterad:
                                </label>
                                <input
                                  defaultChecked={isInvoiced}
                                  className="checkbox"
                                  type="checkbox"
                                  id={`checkbox-${id}`}
                                />
                              </div>
                            </CheckButtonContainer>
                          </ButtonTd>

                          <td>
                            {isProposalApproved && (
                              <ReOpenButton
                                onClick={() => openModal(id)}
                                data-tip
                                data-for="re-open-tip"
                              >
                                <Tooltip id="re-open-tip" place="top" effect="solid" type="dark">
                                  Öppna faktura igen
                                </Tooltip>
                                Återöppna faktura
                              </ReOpenButton>
                            )}
                            {id === parseInt(openedRow) && (
                              <ConfirmModal
                                type="invoice"
                                setOpenedRow={setOpenedRow}
                                itemId={id}
                                page={'admin'}
                              />
                            )}
                          </td>
                        </tr>
                      </Fragment>
                    )
                  })}
                </tbody>
              </InvoiceTable>
            </InvoiceTableContainer>
          )}
        </Container>
      </PageContainer>
    </>
  )
}

export default AdminPage

const InvoiceTableContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  ${device.mobile} {
    overflow-x: auto;
  }
`

const InvoiceTable = styled.table`
  background: white;
  border-spacing: 0px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0 auto;
  table-layout: fixed;
  width: calc(100% - 3rem);

  &:last-of-type {
    margin-bottom: 0 !important;
  }

  thead {
    top: 0;
    color: #222;
    font-weight: 700;
    background-color: #eee;

    tr {
      height: 3rem;
    }

    td {
      &:nth-child(5) {
        text-align: center;
      }
    }
  }

  tr {
    text-align: left;
    height: 3rem;
    transition: all 0.2s ease;
  }

  tbody {
    tr {
      background: ${rgba('#E2EDEE', 0.2)};

      &:nth-child(even) {
        background: #f1f1f1;
      }

      &.handled {
        background: ${rgba('#3cd487', 0.2)};
        cursor: pointer;
      }

      &.copied {
        background: ${rgba('yellow', 0.2)};
        cursor: pointer;
      }

      &:hover:not(.handled):not(.copied) {
        cursor: pointer;
        background: ${rgba('#E2EDEE', 0.6)};
      }

      td {
        &:nth-child(5) {
          font-size: 1.1rem;
          text-align: center;
        }
      }
    }
  }

  td,
  th {
    padding: 0 15px;
    word-wrap: break-word;
    max-width: 150px;
  }
`

const ButtonTd = styled.td`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100px;
`

const CopyButton = styled(Button)`
  opacity: 1;
  background-color: ${({ active }) => (active ? '#3cd487' : '#442770')};
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  width: 75px;
  height: 45px;
  transition: background-color 0.2s ease;
`

const ReOpenButton = styled(CopyButton)`
  font-size: 15px;
  width: 100px;
`

const CheckButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  justify-content: center;
  margin-left: 0.5rem;

  label {
    font-size: 0.8rem;
    cursor: pointer;
  }

  input[type='checkbox'] {
    &:after {
      background: #fff;
      border: 1px solid #777;
      border-radius: 2px;
      content: '';
      cursor: pointer;
      display: block;
      position: relative;
      width: 1.2rem;
      height: 1.2rem;
    }

    &:checked:after {
      border: 1px solid #2d9b62;
      border-radius: 2px;
      background: #2d9b62 url(${checkbox}) no-repeat center;
    }
  }
`

const TypeOfSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const TypeOfSummary = styled.span`
  color: ${({ type }) =>
    type === 'approved' ? '#3cd487' : type === 'contested' ? '#ff2b2b' : '#222'};
  letter-spacing: 0.25px;
`

const StatusColor = styled.span`
  color: ${({ approved }) => (approved ? '#3cd487' : '#e0db53')};
`

const Robot = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(${robot}) no-repeat center;
`

const CopyText = styled.span`
  padding: 10px;
  display: block;
  border-radius: 9999rem;
  font-weight: bold;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  text-align: right;

  &.show {
    visibility: visible;
    animation: slide 1.5s;
  }

  @keyframes slide {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
`
