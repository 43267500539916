import { createContext } from 'react'

const globalContext = createContext({
  user: null,
  isAuthError: false,
  invoice: null,
  loginError: null,
  fetching: false,
  authErrorMessage: null,
  invoiceId: '',
  setAuthErrorMessage: () => {},
  login: () => {},
  logout: () => {},
  setUser: () => {},
  setBookings: () => {},
  setAuthError: () => {},
  setLoginError: () => {},
  setInvoiceId: () => {},
  fetchInvoice: () => {},
  fetchActiveInvoice: () => {},
  contestBooking: () => {},
  contestCall: () => {},
  approveInvoice: () => {},
  reopenInvoice: () => {},
  fetchInternalData: () => {},
  fetchBookings: () => {},
})

export default globalContext
