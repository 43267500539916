import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import axios from 'axios'

import {
  ApprovedPage,
  BookingPage,
  LoginPage,
  InvoicePage,
  HomePage,
  NotFoundPage,
  AdminPage,
} from './pages'
import PrivateRoute from './components/PrivateRoute'
import GlobalStyle from './components/GlobalStyle'

import globalContext from './global-context'

const AUTH_API = process.env.REACT_APP_AUTH_API
const INVOICE_API = process.env.REACT_APP_INVOICE_API
const INVOICE_INTERNAL_API = process.env.REACT_APP_INVOICE_INTERNAL_API

function App() {
  const [sessionAttempt, setSessionAttempt] = useState(false)
  const [user, setUser] = useState()
  const [loginError, setLoginError] = useState()
  const [invoice, setInvoice] = useState()
  const [bookings, setBookings] = useState()
  const [fetching, setFetching] = useState(false)
  const [authErrorMessage, setAuthErrorMessage] = useState(null)
  const [isAuthError, setIsAuthError] = useState(false)
  const [invoiceId, setInvoiceId] = useState()

  const login = async ({ username, password }) => {
    setFetching(true)

    try {
      const response = await axios.post(`${AUTH_API}/token-auth`, {
        username,
        password,
      })

      setUser({
        username,
        token: response.data.token,
      })

      localStorage.setItem(
        'user',
        JSON.stringify({
          username,
          token: response.data.token,
        })
      )
    } catch (error) {
      console.error(error)
      setLoginError('Wrong username or password')
    }

    setFetching(false)
    setIsAuthError(false)
  }

  const fetchInvoice = async id => {
    try {
      const response = await axios.get(`${INVOICE_API}/invoices/${id}`, {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      })

      setInvoice(response.data)
    } catch (error) {
      handleErrors(error)
    }
  }

  const fetchActiveInvoice = async () => {
    try {
      const response = await axios.get(`${INVOICE_API}/invoices`, {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      })

      return response.data
    } catch (err) {
      handleErrors(err)
    }
  }

  const fetchInternalData = async date => {
    try {
      const response = await axios.get(`${INVOICE_INTERNAL_API}/invoices/${date}`, {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      })

      return response.data
    } catch (error) {
      handleErrors(error)
    }
  }

  const fetchClinics = async () => {
    try {
      const response = await axios.get(`${INVOICE_API}/clinics`, {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      })

      return response.data
    } catch (err) {
      handleErrors(err)
    }
  }

  const fetchBookings = async clinicId => {
    try {
      const response = await axios.get(`${INVOICE_API}/bookings?clinic-id=${clinicId}`, {
        headers: {
          authorization: `${user.token}`,
        },
      })

      // Sort bookings by date on default
      setBookings(response.data.sort((a, b) => (a.date > b.date ? 1 : -1)))
      return response.data
    } catch (error) {
      handleErrors(error)
    }
  }

  const contestBooking = async ({ id, reason, description, contest }) => {
    try {
      await axios.post(
        `${INVOICE_API}/bookings/${id}`,
        {
          reason,
          description,
          contest,
        },
        {
          headers: {
            authorization: `Bearer ${user.token}`,
          },
        }
      )
    } catch (error) {
      handleErrors(error)
    }
  }

  const contestCall = async ({ id, reason, description, contest }) => {
    try {
      await axios.post(
        `${INVOICE_API}/calls/${id}`,
        {
          reason,
          description,
          contest,
        },
        {
          headers: {
            authorization: `Bearer ${user.token}`,
          },
        }
      )
    } catch (error) {
      handleErrors(error)
    }
  }

  const approveInvoice = async id => {
    try {
      await axios.post(`${INVOICE_API}/invoices/${id}`, null, {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      })
    } catch (error) {
      handleErrors(error)
    }
  }

  const reopenInvoice = async id => {
    try {
      await axios.post(`${INVOICE_INTERNAL_API}/invoices/${id}/reopen`, null, {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      })
      setInvoiceId(id)
    } catch (e) {
      console.error(e)
    }
  }

  const handleAuthorization = err => {
    switch (err.response?.status) {
      case 400:
        setAuthErrorMessage({
          code: 400,
          message: 'Handlingen misslyckades! Var god försök igen.',
        })
        break
      case 401:
        setAuthErrorMessage({
          code: 401,
          message: 'Logga in på nytt för att få åtkomst till sidan.',
        })
        break
      case 403:
        setAuthErrorMessage({
          code: 403,
          message:
            'Du har tyvärr inte behörighet att läsa sidan. Testa en annan sida eller logga in på nytt.',
        })
        break
      default:
        setAuthErrorMessage({
          code: 500,
          message: 'Någonting gick fel. Testa att logga in på nytt.',
        })
        break
    }
  }

  const loginFromOldAdmin = (username, token) => {
    const query = new URLSearchParams(window.location.search)
    const target = query.get('target')

    let userSession = JSON.stringify({
      username: username,
      token: token,
    })
    localStorage.setItem('user', userSession)
    window.location.replace('/' + target)
  }

  useEffect(() => {
    let userSession = localStorage.getItem('user')
    const query = new URLSearchParams(window.location.search)
    const username = query.get('username')
    const token = query.get('token')

    if (username && token) {
      loginFromOldAdmin(username, token)
    }
    if (userSession) setUser(JSON.parse(userSession))
    setSessionAttempt(true)
  }, [])

  function handleErrors(err) {
    setIsAuthError(true)
    handleAuthorization(err)
  }

  if (!sessionAttempt) return null

  return (
    <>
      <GlobalStyle />
      <globalContext.Provider
        value={{
          user,
          bookings,
          loginError,
          authErrorMessage,
          isAuthError,
          invoiceId,
          setAuthErrorMessage,
          setBookings,
          setUser,
          setIsAuthError,
          setLoginError,
          setInvoiceId,
          login,
          invoice,
          fetchInvoice,
          fetchActiveInvoice,
          fetching,
          contestBooking,
          contestCall,
          approveInvoice,
          reopenInvoice,
          fetchInternalData,
          fetchBookings,
          fetchClinics,
        }}
      >
        <Router>
          <Switch>
            <Route exact path="/login">
              <LoginPage />
            </Route>

            <PrivateRoute path="/bookings/:id">
              <BookingPage />
            </PrivateRoute>

            <PrivateRoute path="/invoice/:id">
              <InvoicePage />
            </PrivateRoute>

            <PrivateRoute path="/admin/:date">
              <AdminPage />
            </PrivateRoute>

            <PrivateRoute path="/approved">
              <ApprovedPage />
            </PrivateRoute>

            <Route exact path="/">
              <HomePage />
            </Route>

            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </globalContext.Provider>
    </>
  )
}

export default App
