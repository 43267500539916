import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { Button } from '../Shared'

const LogoutButton = () => {
  const history = useHistory()

  const handleLogout = () => {
    localStorage.clear()
    history.go('/')
  }

  return <LogoutBtn onClick={handleLogout}>Logga ut</LogoutBtn>
}

export default LogoutButton

const LogoutBtn = styled(Button)`
  background: #777;
  border-radius: 4px;
  transition: background 0.2s ease;
  width: 50%;
  margin-top: 20px;

  &:hover {
    background: #555;
  }
`
